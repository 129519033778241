angular
  .module('workflow')

  .directive('formNcac', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        workflow: '=ngModel'
      },
      templateUrl: 'custom/workflow/views/partials/formNcac.html'
    };
  });
