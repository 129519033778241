angular.module('statistics').controller('StatisticsCtrl', [
  '$scope',
  'GetToken',
  'GetUTypeToken',
  'OrderExportByCompanyYearBilled',
  function($scope, GetToken, GetUTypeToken, OrderExportByCompanyYearBilled) {
    $scope.apiUrl = '@@apiUrl';
    $scope.token = GetToken();
    $scope.xutype = GetUTypeToken();
    $scope.onDownloadByCompanyYearBilled = () =>
      OrderExportByCompanyYearBilled.download();
  }
]);
