angular
  .module('company')

  .factory('CompanyAgentAlertCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class CompanyAgentAlertCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/agent-alert/:id', { id: '@id' });
        }
      }

      return CompanyAgentAlertCrud;
    }
  ]);
