angular
  .module('workflow')

  .directive('formAbout', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        workflow: '=ngModel',
        showPopup: '<'
      },
      templateUrl: 'custom/workflow/views/partials/formAbout.html',
      controller: [
        '$scope',
        '$timeout',
        '$filter',
        'LoggedUser',
        'CompanyAgentAlertCrud',
        'CompanyExpiredCrud',
        'LegalAddress',
        'Dialog',
        function(
          $scope,
          $timeout,
          $filter,
          LoggedUser,
          CompanyAgentAlertCrud,
          CompanyExpiredCrud,
          LegalAddress,
          Dialog
        ) {
          $scope.userType = LoggedUser.getData() && LoggedUser.getData().type;
          $scope.isPM = LoggedUser.isPM();

          $scope.$watch('workflow.DataFT', DataFT => {
            if (!DataFT) return;
            if ($scope.workflow.status !== 'pre_invoiced') return;
            const date = new Date(DataFT);
            date.setHours(12);
            $scope.workflow.DataFT = date.toISOString().substr(0, 10);
          });

          const setTasksDeliveryDates = () => {
            const { delivery_date, projects } = $scope.workflow;
            if (!delivery_date || !new Date(delivery_date).getTime()) return;

            (projects || []).forEach(project => {
              const { batches } = project;
              (batches || []).forEach(batch => {
                const { tasks } = batch;
                (tasks || []).forEach(task => {
                  if (!task) return;
                  task.delivery_date =
                    task.delivery_date || new Date(delivery_date);
                });
              });
            });
          };

          $scope.onDeliveryDateChange = () => $timeout(setTasksDeliveryDates);

          const showCompanyAlert = () => {
            if (!$scope.isPM) return;
            const { company } = $scope.workflow || {};
            const { _id: companyId, settings } = company || {};
            if (!companyId) return;
            const { disableExpiredAmountAlert, unreliable } = settings || {};

            const expiredAmountPromise = disableExpiredAmountAlert
              ? Promise.resolve('')
              : new CompanyExpiredCrud()
                  .get({ id: companyId })
                  .then(
                    ({ expired }) =>
                      expired &&
                      `${$filter('translate')(
                        'company.EXPIRED_MESSAGE'
                      )} ${expired}`
                  )
                  .catch(() => {});

            const unreliableCompanyPromise = Promise.resolve(
              unreliable && $filter('translate')('company.UNRELIABLE_MESSAGE')
            );

            const agentAlertPromise = new CompanyAgentAlertCrud()
              .get({ id: companyId })
              .then(res => res && res.agentAlertMessage)
              .catch(() => {});

            Promise.all([
              expiredAmountPromise,
              unreliableCompanyPromise,
              agentAlertPromise
            ]).then(messages => {
              const message = messages.filter(message => message).join('\n');
              message && new Dialog().info(message);
            });
          };

          const transformBillingAddress = billing => {
            const { note = '' } = $scope.billing || {};
            const obj = angular.copy(billing || {});
            delete obj.billingCompanies;
            obj.note = note;
            return obj;
          };

          const getBillFromCompany = () => {
            const { company } = $scope.workflow || {};
            if (!company || typeof company !== 'object') return;

            $scope.workflow = angular.extend($scope.workflow, {
              billing: {
                ...transformBillingAddress(company),
                legal_address: LegalAddress.get(company)
              }
            });
          };

          const setCompanyCurrency = () => {
            const { company } = $scope.workflow || {};
            let { headquarters } = company || {};
            headquarters = Array.isArray(headquarters) ? headquarters : [];
            const legalHeadquarter =
              headquarters.find(({ legal }) => legal) || {};
            const { address } = legalHeadquarter || {};
            const { currency } = address || {};
            if (!['EUR', 'USD', 'GBP'].includes(currency)) return;

            $scope.workflow.currency = currency;
          };

          $scope.onCompanyChange = () => {
            $timeout(showCompanyAlert);
            $timeout(getBillFromCompany);
            $timeout(setCompanyCurrency);
          };

          $scope.showInvoiceDatePicker = () => {
            const { order } = $scope.workflow || {};
            const { status } = order || {};
            return [
              'internal',
              'not_billable',
              'pre_invoiced',
              'test'
            ].includes(status);
          };

          $scope.onCurrencyChange = currency => {
            if (currency === 'EUR') $scope.workflow.exchangeRate = 1;
          };
        }
      ]
    };
  });
