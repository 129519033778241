const MOCKED_COUNTERS_ORDERS = {
  counters: {
    draft: -1,
    standby: -1,
    pm_assigned: -1,
    progress: -1,
    delivering: -1,
    delivered: -1,
    dataconfirm: -1,
    dataconfirmed: -1,
    billed: -1,
    request_received: -1,
    tobill: -1,
    test: -1,
    internal: -1,
    not_billable: -1,
    pre_invoiced: -1
  },
  total: -1
};

const MOCKED_COUNTERS_QUOTES = {
  counters: {
    draft: -1,
    standby: -1,
    pm_assigned: -1,
    waiting: -1,
    approved: -1,
    notapproved: -1,
    canceled: -1,
    request_received: -1
  },
  total: -1
};

angular
  .module('workflow')

  .directive('cardFilterStatus', [
    function() {
      return {
        restrict: 'E',
        require: '^ngModel',
        replace: true,
        scope: {
          status: '=ngModel',
          performSearch: '&search',
          prefix: '@',
          contributor: '<',
          resetPage: '&',
          singleSelection: '<'
        },
        templateUrl: 'custom/workflow/views/partials/cardFilterStatus.html',
        controller: [
          '$scope',
          '$controller',
          '$timeout',
          'QuoteCountersCrud',
          'OrderCountersCrud',
          'WorkflowStore',
          'TaskCountersCrud',
          'LoggedUser',
          'OrderCountersCache',
          'QuoteCountersCache',
          'TaskCountersCache',
          function(
            $scope,
            $controller,
            $timeout,
            QuoteCountersCrud,
            OrderCountersCrud,
            WorkflowStore,
            TaskCountersCrud,
            LoggedUser,
            OrderCountersCache,
            QuoteCountersCache,
            TaskCountersCache
          ) {
            const { contributor, prefix } = $scope;
            const isPM = LoggedUser.isPM();
            $scope.isPM = isPM;

            let cache;
            let crud;
            let filters = {};

            if (prefix === 'quote') {
              crud = new QuoteCountersCrud();
              cache = QuoteCountersCache;
            } else if (prefix === 'order') {
              crud = new OrderCountersCrud();
              cache = OrderCountersCache;
            } else if (prefix === 'task') {
              crud = new TaskCountersCrud();
              cache = TaskCountersCache;
            }

            if (isPM && prefix === 'task' && contributor) {
              filters.contributor = contributor._id;
            }

            $controller('ListCtrl', {
              $scope: $scope,
              crud: crud,
              packageName: 'counters',
              dataName: 'counters',
              filters
            });

            $scope.search = doNotResetPage => {
              $timeout(() => {
                $scope.performSearch();
                !doNotResetPage && $scope.resetPage();
              });
            };

            const loadList = reloadCounters => {
              const { loadList, search } = $scope;
              search(true);
              const counters = cache.get();

              if (reloadCounters)
                return loadList().then(() => cache.set($scope.counters));
              if (counters) return ($scope.counters = counters);

              if (prefix === 'quote') {
                cache.set(($scope.counters = MOCKED_COUNTERS_QUOTES));
              } else if (prefix === 'order') {
                cache.set(($scope.counters = MOCKED_COUNTERS_ORDERS));
              } else if (prefix === 'task') {
                loadList().then(() => cache.set($scope.counters));
              }
            };

            $scope.onReloadCounters = () => loadList(true);

            const unregisterReloadCounters = $scope.$on(
              `reloadCounters.${prefix}`,
              () => loadList(true)
            );

            $scope.$on('$destroy', () => {
              unregisterReloadCounters();
            });

            WorkflowStore.addListener('onDelete', () => loadList(true));

            loadList();

            $scope.toggleSelection = status => {
              if ($scope.singleSelection) {
                if (status === $scope.status) {
                  delete $scope.status;
                  return $scope.search();
                }
                delete $scope.status;
              }
              const selected = $scope.status ? $scope.status.split(',') : [];
              const i = selected.indexOf(status);
              if (i > -1) {
                selected.splice(i, 1);
              } else {
                selected.push(status);
              }
              $scope.status = selected.join(',') || null;
              $scope.search();
            };

            $scope.isSelected = status => {
              const selected = $scope.status ? $scope.status.split(',') : [];
              return selected.indexOf(status) > -1;
            };

            $scope.isAllSelected = () => {
              const { counters = {} } = $scope.counters || {};
              return Object.keys(counters).every(c => $scope.isSelected(c));
            };

            $scope.toggleAll = () => {
              if ($scope.isAllSelected()) {
                $scope.status = null;
              } else {
                const { counters = {} } = $scope.counters || {};
                $scope.status = Object.keys(counters).join(',');
              }
              $scope.search();
            };
          }
        ]
      };
    }
  ]);
