angular
  .module('workflow')

  .directive('workflowCheckAi', [
    function() {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          ai: '='
        },
        templateUrl: 'custom/workflow/views/partials/workflowCheckAi.html',
        controller: ['$scope', function($scope) {}]
      };
    }
  ]);
