angular.module('workflow').directive('conditionsOfSupply', function() {
  return {
    restrict: 'E',
    require: '^ngModel',
    replace: true,
    scope: {
      conditionsOfSupply: '=ngModel'
    },
    templateUrl: 'custom/workflow/views/partials/conditionsOfSupply.html',
    controller: [
      '$scope',
      '$filter',
      '$timeout',
      '$translate',
      'WorkflowStore',
      function($scope, $filter, $timeout, $translate, WorkflowStore) {
        $scope.editArray = [];

        const unwatch = $scope.$watch(
          'conditionsOfSupply',
          conditionsOfSupply => {
            if (!conditionsOfSupply) return;
            unwatch();
          }
        );

        $scope.delete = conditionOfSupplyIndex => {
          $scope.conditionsOfSupply.splice(conditionOfSupplyIndex, 1);
          $scope.editArray.splice(conditionOfSupplyIndex, 1);
        };

        $scope.edit = index => {
          $scope.editArray[index] = !$scope.editArray[index];
        };

        $scope.addExtraService = () => {
          unwatch();

          if (
            $scope.conditionsOfSupply &&
            $scope.conditionsOfSupply.some(c => !c.title && !c.text)
          )
            return;
          const conditionOfSupplyLength =
            $scope.conditionsOfSupply &&
            Array.isArray($scope.conditionsOfSupply) &&
            $scope.conditionsOfSupply.length;
          $scope.editArray = conditionOfSupplyLength
            ? new Array(conditionOfSupplyLength)
            : [];
          $scope.editArray[$scope.editArray.length] = true;
          const newService = {};
          $scope.conditionsOfSupply && Array.isArray($scope.conditionsOfSupply)
            ? $scope.conditionsOfSupply.push(newService)
            : ($scope.conditionsOfSupply = [newService]);
        };

        WorkflowStore.listenTo(function(data) {
          if (!Object.keys(data).length) return;
          $timeout(() => {
            setDeliveryDate(data);
            // setPaymentConditions()
          });
        });

        function setDeliveryDate(workflow) {
          const {
            offer: { template: { lang: templateLang = 'it' } = {} } = {}
          } = workflow;
          const itTitle = $translate.instant(
            'quote.DELIVERY_DATE',
            undefined,
            undefined,
            'it'
          );
          const enTitle = $translate.instant(
            'quote.DELIVERY_DATE',
            undefined,
            undefined,
            'en'
          );
          const delivery_date = {
            title: `${templateLang === 'it' ? itTitle : enTitle}:`,
            text: workflow.delivery_date
              ? new Date(workflow.delivery_date).toLocaleDateString('it-IT')
              : ''
          };

          const { conditionsOfSupply = [] } = $scope;
          const delivery_date_index = conditionsOfSupply.findIndex(
            ({ title } = {}) => {
              return [`${itTitle}:`, `${enTitle}:`].includes(title);
            }
          );

          if (delivery_date_index > -1) {
            $scope.conditionsOfSupply[delivery_date_index] = delivery_date;
          } else {
            $scope.addExtraService();
            $scope.conditionsOfSupply[
              $scope.conditionsOfSupply.length - 1
            ] = delivery_date;
          }
        }

        // function setPaymentConditions() {
        //   const paymentConditions = {
        //     title: $filter('translate')('contributors.PAYMENT'),
        //     text: ''
        //   }
        //
        //   const payment_conditions_index = $scope.conditionsOfSupply && $scope.conditionsOfSupply.findIndex(c => c.title == paymentConditions.title)
        //   if (payment_conditions_index == -1) {
        //     $scope.addExtraService()
        //     $scope.conditionsOfSupply[$scope.conditionsOfSupply.length - 1] = paymentConditions
        //   }
        //
        // }
      }
    ]
  };
});
