angular
  .module('workflow')

  .controller('WorkflowEmailController', [
    '$scope',
    '$filter',
    'Confirm',
    'Dialog',
    'WorkflowSendEmail',
    'WorkflowStore',

    function(
      $scope,
      $filter,
      Confirm,
      Dialog,
      WorkflowSendEmail,
      WorkflowStore
    ) {
      $scope.$watch('workflow.emails.delivery.sentEmails', sentEmails => {
        Array.isArray(sentEmails) &&
          sentEmails.sort((a, b) => (a.sentAt < b.sentAt ? 1 : -1));
      });

      $scope.$watch('workflow.emails.quote.sentEmails', sentEmails => {
        Array.isArray(sentEmails) &&
          sentEmails.sort((a, b) => (a.sentAt < b.sentAt ? 1 : -1));
      });

      $scope.$watch('workflow.customer', customer => {
        const { email: customerEmail, contacts: customerContacts } =
          customer || {};
        const { email: customerContactsEmail } = customerContacts || {};
        $scope.customerEmail = customerEmail || customerContactsEmail || '';
      });

      $scope.sendMail = type => {
        if (!$scope.customerEmail)
          return new Dialog().error(
            $filter('translate')('workflow.EMAIL_DELIVER_ORDER_NO_EMAIL_ERR')
          );

        if (!['manualDelivery', 'manualSendQuote'].includes(type)) return;

        const { _id, emails } = $scope.workflow || {};
        const { delivery, quote } = emails || {};
        const { customerNotes: deliveryCustomerNotes = '' } = delivery || {};
        const { customerNotes: quoteCustomerNotes = '' } = quote || {};
        const customerNotes =
          type === 'manualDelivery'
            ? deliveryCustomerNotes
            : quoteCustomerNotes;

        if (!customerNotes)
          return new Dialog().error(
            $filter('translate')('workflow.EMAIL_DELIVER_ORDER_EMPTY_ERR')
          );

        new Confirm()
          .info(
            $filter('translate')(
              type === 'manualDelivery'
                ? 'workflow.EMAIL_DELIVER_ORDER_MSG'
                : 'workflow.EMAIL_SEND_QUOTE_MSG'
            )
          )
          .then(() => new WorkflowSendEmail(_id).save({ customerNotes, type }))
          .then(response => response && WorkflowStore.emit('save', response));
      };
    }
  ]);
