angular
  .module('company')

  .controller('CompanyTabCtrl', [
    '$scope',
    'CompanyStore',
    'LoggedUser',

    function($scope, CompanyStore, LoggedUser) {
      $scope.store = CompanyStore;
      $scope.isPM = LoggedUser.isPM();

      //On change listener
      CompanyStore.listenTo(function(data) {
        $scope.company = data;
      });

      //Emit save action
      $scope.save = function() {
        CompanyStore.emit('save');
      };

      $scope.undo = function() {
        $scope.$parent.performUndo();
      };
    }
  ]);
