angular
  .module('workflow')

  .controller('WorkflowListCtrl', [
    '$scope',
    '$controller',
    '$timeout',
    '$filter',
    '$state',
    '$stateParams',
    '$modal',
    'QuoteCrud',
    'OrderCrud',
    'QuoteCountersCrud',
    'OrderCountersCrud',
    'QuoteExport',
    'OrderExport',
    'Confirm',
    'WorkflowStore',
    'LoggedUser',
    'WorkflowModel',
    'WorkflowCrud',
    'CleanWorkflow',
    'Dialog',
    'LSPrefix',
    'UserLang',
    'ContributorSearchCrud',
    'UpdateTaskContributorPrices',
    'DoneNotBilledTasksExport',
    'ZohoWorkflowBulkSync',
    'ZohoWorkflowSync',
    'WorkflowLinkAsParentCrud',
    'OrderExportByCompanyYearBilled',

    function(
      $scope,
      $controller,
      $timeout,
      $filter,
      $state,
      $stateParams,
      $modal,
      QuoteCrud,
      OrderCrud,
      QuoteCountersCrud,
      OrderCountersCrud,
      QuoteExport,
      OrderExport,
      Confirm,
      WorkflowStore,
      LoggedUser,
      WorkflowModel,
      WorkflowCrud,
      CleanWorkflow,
      Dialog,
      LSPrefix,
      UserLang,
      ContributorSearchCrud,
      UpdateTaskContributorPrices,
      DoneNotBilledTasksExport,
      ZohoWorkflowBulkSync,
      ZohoWorkflowSync,
      WorkflowLinkAsParentCrud,
      OrderExportByCompanyYearBilled
    ) {
      const confirm = new Confirm();
      const user = LoggedUser.getData();
      const isCustomer = LoggedUser.isCustomer();
      const requireQtMsg = $filter('translate')('order.REQUIRE_QUOTE');
      const zohoWorkflowBulkSync = new ZohoWorkflowBulkSync();

      $scope.isCustomer = isCustomer;
      $scope.isPM = LoggedUser.isPM();
      $scope.isSuperAdmin = LoggedUser.isSuperAdmin();
      $scope.breadCrumbOrders = $filter('translate')('order.ORDERS');
      $scope.breadCrumbQuotes = $filter('translate')('quote.QUOTES');
      $scope.currentLang = UserLang.get();

      zohoWorkflowBulkSync
        .get()
        .then(({ updatedAt }) => ($scope.lastZohoSync = updatedAt))
        .catch(() => {});

      let pkgName, tblParams, crd, delPkgName, createMsg, deleteMsg;
      switch ($state.current.url) {
        case '/orders':
          $scope.crudCounters = new OrderCountersCrud();
          $scope.prefix = 'order';
          $scope.tableName = 'ordersList';
          pkgName = 'orders';
          tblParams = 'ordersList';
          crd = OrderCrud;
          delPkgName = 'ordersDelete';
          createMsg = $filter('translate')('order.CREATE_MESSAGE_DRAFT');
          deleteMsg = $filter('translate')('order.DELETE_REQUEST_CONFIRM');
          break;
        case '/quotes':
          $scope.crudCounters = new QuoteCountersCrud();
          $scope.prefix = 'quote';
          $scope.tableName = 'quotesList';
          pkgName = 'quotes';
          tblParams = 'quotesList';
          crd = QuoteCrud;
          delPkgName = 'quotesDelete';
          createMsg = $filter('translate')('quote.CREATE_MESSAGE_DRAFT');
          deleteMsg = $filter('translate')('quote.DELETE_REQUEST_CONFIRM');
          break;
      }

      WorkflowStore.clear();

      const { contributor, linkingToParent, fromWorkflowId } = $stateParams;
      const storageName = LSPrefix + $scope.prefix + '.filters';
      const savedFilters = sessionStorage.getItem(storageName);
      const parsedFilters = savedFilters && JSON.parse(savedFilters);

      if (contributor) {
        $scope.contributor = contributor;
        $scope.filters = { contributor: contributor._id };
        $scope.notifyFilters = true;
        $scope.skipInitialTableLoading = true;
      } else if (parsedFilters) {
        $scope.filters = parsedFilters;
        $scope.notifyFilters = true;
        $scope.skipInitialTableLoading = true;
      } else {
        $scope.notifyFilters = false;
      }

      if (linkingToParent && fromWorkflowId) {
        $scope.linkingToParent = linkingToParent;
        $scope.fromWorkflowId = fromWorkflowId;
      }

      $controller('DataTableCtrl', {
        $scope: $scope,
        crud: new crd(),
        packageName: pkgName,
        tableParams: tblParams,
        filters: {
          sort: {
            created_at: -1,
            title: 1
          }
        },
        parser: null
      });

      $scope.setFilterNotification = function(show) {
        $scope.notifyFilters = show;
      };

      $controller('DeleteBaseCtrl', {
        $scope: $scope,
        crud: new WorkflowCrud($scope),
        packageName: delPkgName,
        dataName: 'data'
      });

      $scope.delete = function(item) {
        confirm.info(deleteMsg).then(function() {
          $scope.data = item;

          $scope.processRemoving().then(function() {
            WorkflowStore.emit('onDelete');
            $scope.reload($scope.filters);
          });
        });
      };

      $scope.selectWorkflowTemplate = () => {
        const modal = $modal.open({
          animation: true,
          templateUrl:
            'custom/workflow/views/partials/modalWorkflowSelection.html',
          controller: 'ModalWorkflowSelectionCtrl',
          size: 'md',
          resolve: {
            currentRoute: () => $state.current.url.split('/')[1]
          }
        });

        modal.result.then(selection => $timeout(() => add(selection), 250));
      };

      const add = ({
        selectedCompany,
        selectedWorkflow,
        type: templateType,
        asQuote
      }) => {
        const isTemplate =
          ['24h', 'template', 'duplicated'].includes(templateType) &&
          selectedWorkflow;

        const model = isTemplate ? selectedWorkflow : new WorkflowModel();
        model.templateType = templateType;

        const performAdd = function() {
          if (user && user.type === 'customer') {
            model.company = user.company;
            model.iso17100 =
              user.company &&
              user.company.settings &&
              user.company.settings.iso17100;
            model.customer = {
              _id: user._id,
              active: user.active,
              type: user.type,
              email: user.email,
              first_name: user.first_name,
              last_name: user.last_name,
              job_role: user.job_role,
              lang: user.lang
            };
          } else if ($scope.isPM && selectedCompany) {
            model.company = selectedCompany;
          }

          if (model.company) {
            delete model.company.workflows24h;
            delete model.company.workflowTemplates;
          }

          const crud = new WorkflowCrud($scope);

          crud.save(model).then(savedWorkflow => {
            $state.go('^.workflow-wizard.step1', { id: savedWorkflow._id });
          });
        };

        if (
          user.company &&
          user.company.settings &&
          user.company.settings.requireQuote &&
          $state.current.url === '/orders' &&
          !asQuote
        ) {
          confirm.info(requireQtMsg).then(function() {
            model.quote.status = 'draft';
            model.type = 'quote';
            performAdd();
          });
        } else {
          if (asQuote) {
            model.order = { status: '' };
            model.quote = { status: 'draft' };
            model.type = 'quote';
          } else
            switch ($state.current.url) {
              case '/orders':
                model.order.status = 'draft';
                model.type = 'order';
                break;
              case '/quotes':
                model.quote.status = 'draft';
                model.type = 'quote';
                break;
            }
          performAdd();
        }
      };

      $scope.show = function(workflow) {
        if (workflow && workflow.complete) {
          switch (workflow.type) {
            case 'quote':
              $state.go('^.quote.projects.list', { id: workflow._id });
              break;
            case 'order':
              $state.go('^.order.projects.list', { id: workflow._id });
              break;
          }
        } else {
          $state.go('^.workflow-wizard', { id: workflow._id });
        }
      };

      $scope.download = function() {
        Promise.resolve()
          // confirm
          //   .info(
          //     // $filter('translate')('DOWNLOAD_REQUEST') +
          //     //   '\n' +
          //     $filter('translate')('DOWNLOAD_REQUEST_ALL_COLUMNS')
          //   )
          .then(() => {
            const exportFilters = Object.assign({}, $scope.filters, {
              showFullTable: true
            });
            switch ($state.current.url) {
              case '/orders':
                OrderExport.download(exportFilters);
                break;
              case '/quotes':
                QuoteExport.download(exportFilters);
                break;
            }
          });
      };

      $scope.downloadBilled = function() {
        switch ($state.current.url) {
          case '/orders':
            OrderExportByCompanyYearBilled.download();
            break;
          default:
            break;
        }
      };

      $scope.downloadDoneNotBilledTasks = () => {
        confirm
          .info($filter('translate')('DOWNLOAD_REQUEST'))
          .then(DoneNotBilledTasksExport.download)
          .catch(() => {});
      };

      $scope.zohoSync = ({ _id }) => {
        confirm.info($filter('translate')('zoho.SYNC_MSG')).then(() => {
          new ZohoWorkflowSync(_id)
            .save()
            .then(() => $scope.reload($scope.filters))
            .catch(error => {
              Sentry.captureException(error);
              $timeout(
                () =>
                  new Dialog().error($filter('translate')('zoho.SYNC_ERROR')),
                250
              );
            });
        });
      };

      const getWorkflowDetail = id => {
        return new WorkflowCrud($scope).get({ id }).catch(error => {
          Sentry.captureException(error);
          $timeout(
            () => new Dialog().error($filter('translate')('error.RECEIVE_ERR')),
            250
          );
        });
      };

      const getUpdatedContributors = workflow => {
        const contributors = [];
        workflow.projects.forEach(p =>
          p.batches.forEach(b =>
            b.tasks.forEach(t => contributors.push(...t.contributors))
          )
        );
        return Promise.all(
          contributors.map(({ _id }) => getContributor(_id))
        ).then(res => res.flat());
      };

      const getContributor = _id => {
        const crud = new ContributorSearchCrud($scope);
        return crud.get({ _id }).catch(error => {
          Sentry.captureException(error);
          $timeout(
            () => new Dialog().error($filter('translate')('error.RECEIVE_ERR')),
            250
          );
        });
      };

      $scope.duplicate = ({ _id }, { asQuote } = {}) => {
        const prefix = asQuote ? 'quote' : $scope.prefix;
        let message = $filter('translate')('workflow.DUPLICATE_WORKFLOW_MSG_1');
        message += $filter('translate')(
          `${prefix}.${prefix.toUpperCase()}`
        ).toLowerCase();
        message += $filter('translate')('workflow.DUPLICATE_WORKFLOW_MSG_2');
        confirm
          .show(message)
          .then(() => getWorkflowDetail(_id))
          .then(workflow => {
            if (!workflow) return;
            const type = 'duplicated';
            const cleanedWorkflow = CleanWorkflow(workflow, type);

            getUpdatedContributors(cleanedWorkflow).then(contributors => {
              const selectedWorkflow = UpdateTaskContributorPrices(
                cleanedWorkflow,
                contributors
              );
              add({
                type,
                selectedWorkflow,
                asQuote
              });
            });
          });
      };

      $scope.isReadOnly = workflow =>
        (isCustomer && workflow && workflow.complete) ||
        (workflow && workflow.quote && workflow.quote.status === 'canceled') ||
        (workflow && workflow.quote && workflow.quote.status === 'cancelled') ||
        (workflow && workflow.order && workflow.order.status === 'canceled') ||
        (workflow && workflow.order && workflow.order.status === 'cancelled');

      const formatContributorsInfo = ({ projects }) => {
        const contributorsInfo = [];
        if (!Array.isArray(projects)) return contributorsInfo;

        projects.forEach(project => {
          const { batches = [], services = [] } = project;
          if (!Array.isArray(batches) || !Array.isArray(services)) return;

          const [firstService] = services;
          let { source = {}, target = [] } = firstService || {};
          target = (target && target[0]) || {};
          const { name: sourceName = '', value: sourceValue = '' } = source;
          source =
            (sourceName && sourceName[$scope.currentLang]) || sourceValue || '';
          const { name: targetName = '', value: targetValue = '' } = target;
          target =
            (targetName && targetName[$scope.currentLang]) || targetValue || '';
          const combo = [source, target].filter(value => value).join(' - ');

          batches.forEach(({ tasks }) => {
            if (!Array.isArray(tasks)) return;

            tasks.forEach(task => {
              const { contributors = [], name } = task;
              if (!name || !Array.isArray(contributors) || !contributors.length)
                return;

              const taskName = name[$scope.currentLang] || name || '';
              contributors.forEach(contributor => {
                const {
                  first_name = '',
                  last_name = '',
                  company_name = '',
                  lsp,
                  task_status
                } = contributor;

                if (['notapproved', 'not_confirmed'].includes(task_status))
                  return;

                const contributorName = lsp
                  ? company_name
                  : `${first_name} ${last_name}`;
                contributorsInfo.push({ contributorName, taskName, combo });
              });
            });
          });
        });
        return contributorsInfo;
      };

      $scope.openContributorsInfo = ({ _id }) => {
        if (!$scope.isPM) return;

        getWorkflowDetail(_id)
          .then(workflow => formatContributorsInfo(workflow))
          .then(contributors => {
            return contributors;
          })
          .then(contributors => ($scope.contributorsInfo = contributors))
          .then(() => ($scope.showContributorsInfo = true))
          .catch(() => {});
      };

      $scope.onShowFullTableChange = () =>
        $timeout(() => {
          $scope.filters = $scope.filters || {};
          if ($scope.showFullTable) $scope.filters.showFullTable = true;
          else delete $scope.filters.showFullTable;
          $scope.reload($scope.filters);
        });

      const getFirstInvoice = workflow => {
        let { additionalInvoices } = workflow || {};
        additionalInvoices = additionalInvoices || [];

        return (
          additionalInvoices.find(
            invoice => invoice && invoice.tipoFT === 'fattura'
          ) || {}
        );
      };

      $scope.getDataFT = workflow => {
        if (!workflow) return;

        const { DataFT } = workflow;
        if (DataFT) return DataFT;

        const { DataFT: firstDataFT } = getFirstInvoice(workflow) || {};
        return firstDataFT;
      };

      $scope.getNumeroFT = workflow => {
        if (!workflow) return;

        const { numeroFT } = workflow;
        if (numeroFT) return numeroFT;

        const { numeroFT: firstNumeroFT } = getFirstInvoice(workflow) || {};
        return firstNumeroFT;
      };

      $scope.zohoBulkSync = () => {
        new Confirm()
          .show($filter('translate')('zoho.BULK_SYNC_MSG'))
          .then(() => {
            zohoWorkflowBulkSync
              .save()
              .then(() => {
                $timeout(() => {
                  new Dialog().success(
                    $filter('translate')('zoho.BULK_SYNC_SUCCESS')
                  );
                }, 250);
              })
              .catch(error => {
                Sentry.captureException(error);
                $timeout(
                  () =>
                    new Dialog().error($filter('translate')('zoho.SYNC_ERROR')),
                  250
                );
              });
          });
      };

      $scope.downloadCustomerReport = () => {
        new Dialog().success($filter('translate')('DOWNLOAD_REPORT_SUCCESS'));
      };

      $scope.linkAsParent = selectedWorkflow => {
        if (!selectedWorkflow || !selectedWorkflow._id) return;
        if (!linkingToParent || !fromWorkflowId) return;

        const { packageExpiresAt } = selectedWorkflow;
        const packageExpiresAtDate =
          packageExpiresAt && new Date(packageExpiresAt);
        const packageExpiresAtTime =
          packageExpiresAtDate && packageExpiresAtDate.getTime();

        if (packageExpiresAtTime && packageExpiresAtTime < Date.now()) {
          return new Dialog().error(
            $filter('translate')('order.LINK_TO_PARENT_EXPIRED_ERROR')
          );
        }

        if (selectedWorkflow._id === fromWorkflowId) {
          return new Dialog().error(
            $filter('translate')('order.LINK_TO_PARENT_SAME_ORDER_ERROR')
          );
        }
        // if (
        //   !selectedWorkflow.order ||
        //   selectedWorkflow.order.status !== 'billed'
        // ) {
        //   return new Dialog().error(
        //     $filter('translate')('order.LINK_TO_PARENT_NOT_INVOICED_ERROR')
        //   );
        // }
        if (!selectedWorkflow.order) {
          return new Dialog().error(
            $filter('translate')('order.LINK_TO_PARENT_QUOTE_ERROR')
          );
        }
        if (selectedWorkflow.parentOrder) {
          return new Dialog().error(
            $filter('translate')('order.LINK_TO_PARENT_ALREADY_LINKED_ERROR')
          );
        }

        new WorkflowLinkAsParentCrud(selectedWorkflow._id)
          .update({ childWorkflowId: fromWorkflowId })
          .then(() => {
            $state.go('^.order.pre_invoiced', { id: selectedWorkflow._id });
            new Dialog().success($filter('translate')('crud.SAVED'));
          })
          .catch(e => {
            console.error(e);
            new Dialog().error($filter('translate')('error.RECEIVE_ERR'));
          });
      };
    }
  ]);
