angular
  .module('workflow')

  .factory('WorkflowSendEmail', [
    'Crud',

    function(Crud) {
      'use strict';

      class WorkflowSendEmail extends Crud {
        constructor(id) {
          super(null, '@@apiUrl' + '/send-request' + `/${id}`, { id: '@id' });
        }
      }
      return WorkflowSendEmail;
    }
  ]);
